export default {
  enabled: process.env.VUE_APP_BROADCAST_ENABLED === 'true',
  pusher: {
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  },
  websocket: {
    hostname: process.env.VUE_APP_WEBSOCKET_HOST,
    forceTLS: process.env.VUE_APP_WEBSOCKET_TLS === 'true',
  },
  soketi: {
    key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
    wsHost: process.env.VUE_APP_MIX_PUSHER_HOST,
    wsPort: process.env.VUE_APP_MIX_PUSHER_PORT,
    wssPort: process.env.VUE_APP_MIX_PUSHER_PORT,
  }
}
